@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'normalize.css/normalize.css';
@import 'grapesjs/dist/css/grapes.min.css';
@import 'grapick/dist/grapick.min.css';
@import '@event-calendar/core/index.css';

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

@include mat.all-component-themes($my-theme);

/* You can add global styles to this file, and also import other style files */

*,
::after,
::before {
  border-style: none;
}

:root {
  --space-m: 0.5rem;

  --tui-background-accent-1: #5c2fff;
  --tui-background-accent-1-hover: #3c14d2;
  --tui-text-action: #5c2fff;
  --tui-text-action-hover: #3c14d2;

  scroll-behavior: smooth;
  scrollbar-color: var(--color-main-2);
  scrollbar-width: thin;
  // Chrome, Edge и Safari.
  ::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: var(--color-neutral-2);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--color-main-2);
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-main-2);
  }
}

html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--color-text);
  font-size: 14px !important;
}

iframe {
  border: none;
}

html[data-theme='Dark'] {
  pattern#grid path {
    stroke-opacity: 0.25;
  }

  .content-black {
    border: 0.1rem dashed #fff;
  }

  .content-transparent {
    border-color: #fff !important;
  }

  .mat-header-cell,
  .mat-paginator-range-label,
  .mat-sort-header-arrow,
  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
  .mat-select-value,
  .mat-select-arrow-wrapper,
  .mat-select-arrow,
  .mat-option {
    color: #fff !important;
  }

  .mat-select-panel {
    background: var(--ui-background-box) !important;
    color: #fff !important;
  }
}

.graph .edge circle {
  stroke: var(--ui-background) !important;
}

.graph .edge {
  stroke: var(--ui-color-main) !important;
}

.common-dialog .mat-dialog-container {
  background-color: var(--ui-background-box) !important;
  border-radius: 1rem;
  padding: 0 !important;
  max-height: 80vh;
}

.fullscreen-dialog .mat-dialog-container {
  background-color: var(--ui-background-box) !important;
  overflow: hidden;
  padding: 0 !important;
}

.mat-dialog-container {
  background-color: var(--color-mainBackground);
  overflow: hidden;
  color: inherit;
}

gridster-preview {
  background-color: var(--ui-background-mood) !important;
}

.mat-menu-item {
  background: inherit !important;
  color: rgb(0, 0, 0) !important;
}

h1 {
  color: var(--color-text);
  font: var(--ui-font-title-bold);
  font-size: 2rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.75rem;
  line-height: 100%;
  margin: 0;
  margin-bottom: 0.75rem;
  padding: 0;
}

h3 {
  color: var(--color-text);
  font: var(--ui-font-title);
  font-size: 1.3rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

h4 {
  color: var(--color-text);
  font: var(--ui-font-title-bold);
  font-size: 0.95rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

h5 {
  color: var(--color-text);
  font: var(--ui-font-title);
  font-size: 0.95rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

a {
  font: var(--color-text);
  text-decoration: none;
}

a {
  color: var(--ui-color-dfn);

  &:hover {
    color: var(--ui-color-link);
  }

  &.active,
  a:active {
    color: var(--ui-color-active);
  }
}

hr {
  height: 1px;
}

.box-block {
  background: var(--ui-background-box);
  border-radius: 0.5rem;
}

.cdk-overlay-dark-backdrop {
  opacity: 0.6;
  background-blend-mode: overlay;
}

campaign-schema.navigation-show nav {
  display: block;
}

gridster {
  background: transparent !important;
}

input,
textarea {
  &::input-placeholder {
    color: var(--ui-color-dfn);
  }

  &::-moz-input-placeholder {
    color: var(--ui-color-dfn);
  }

  &::input-placeholder {
    color: var(--ui-color-dfn);
  }

  &::input-placeholder {
    color: var(--ui-color-dfn);
  }

  &:-webkit-input-placeholder {
    color: var(--ui-color-dfn);
  }

  &:-moz-input-placeholder {
    color: var(--ui-color-dfn);
  }

  &:input-placeholder {
    color: var(--ui-color-dfn);
  }

  &:input-placeholder {
    color: var(--ui-color-dfn);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

html[data-theme='Light'] {
  --color-mainBackground: #fff;
}

html[data-theme='Dark'] {
  --color-mainBackground: #000;
}
